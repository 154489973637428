.amountBtn,
.amountInput {
  font-size: 1rem;
  font-weight: 600;
}

.amountBtn {
  @apply text-black block;

  border: 1px solid #212121;
  border-radius: 4px;
  height: 30px;
  line-height: 1;
  width: 30px;
}

.amountBtn:hover {
  background-color: #212121;
  border: 1px solid #212121;
  color: #ffffff;
}

.amountInput {
  -moz-appearance: textfield;
  background-color: transparent;
  height: 30px;
  padding: 0 1.875rem;
  text-align: center;
  width: calc(2ch + 60px);
}

.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}