.root {
  @apply sticky top-8 bg-primary z-40 transition-all duration-150;
  min-height: 74px;
}

.nav {
  @apply relative py-4 md:py-4 grid grid-cols-2 gap-4 items-center justify-items-center;
}

.navMenu {
  @apply hidden sm:block;
}

.openedMenu {
  @apply block;
}

.logo {
  @apply justify-self-start;
}

.link {
  @apply inline-flex items-center leading-6 mx-4
  transition ease-in-out duration-75 cursor-pointer
  text-2xl font-semibold text-black;
}

.link:focus {
  @apply outline-none text-accent-8;
}

.actions {
  @apply justify-self-end flex items-center gap-1;
}

.actionsBtn {
  @apply p-2 relative;
}

.cartItemsCount {
  @apply absolute bg-black;

  border-radius: 50%;
  bottom: -0.3rem;
  line-height: 1;
  padding: 3px 6px;
  right: -0.3rem;
  text-align: center;
  vertical-align: middle;
}

@media screen and (min-width: 640px) {
  .nav {
    grid-template-columns: 1fr auto 1fr
  }

  .link {
    @apply text-base text-accent-5;
  }

  .link:hover {
    @apply text-accent-9;
  }

  .menuBtn {
    display: none;
  }
}