.cartOffcanvas {
  @apply p-4 flex flex-col gap-8 h-full overflow-hidden;
  background-color: #fff;
}

.cartOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.lineItem + .lineItem {
  @apply mt-4 md:mt-8;
}

@media screen and (min-width: 768px) {
  .cartOffcanvas {
    @apply p-8;
  }
}