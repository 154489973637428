.root {
  @apply border-t border-accent-2;
}

.link {
  & > svg {
    @apply transform duration-75 ease-linear;
  }

  &:hover > svg {
    @apply scale-110;
  }
}

.logo {
  @apply block mb-4;
  height: 30px;
  width: 130px;
}

.logo svg {
  height: 100%;
  width: 100%;
}

.socialsDivider {
  background-color: #212121;
  display: block;
  height: 1px;
  width: 10px;
}