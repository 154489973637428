.Hero_title__WzKPm {
  margin-bottom: 4rem;
  margin-top: 4rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}@media (min-width: 640px) {.Hero_title__WzKPm {
    margin-top: 8rem;
  }.Hero_title__WzKPm {
    font-size: 3.75rem;
    line-height: 1;
  }
}@media (min-width: 768px) {.Hero_title__WzKPm {
    font-size: 4.5rem;
    line-height: 1;
  }
}@media (min-width: 1024px) {.Hero_title__WzKPm {
    font-size: 6rem;
    line-height: 1;
  }
}@media (min-width: 1280px) {.Hero_title__WzKPm {
    font-size: 8rem;
    line-height: 1;
  }
}.Hero_title__WzKPm {
  font-weight: 600;
  letter-spacing: 10px;
  line-height: 1;
  text-align: center;
  white-space: pre-line;
}

.Button_root__G_l9X {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  background-color: var(--accent-9);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  color: var(--accent-0);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 64px
}

.Button_root__G_l9X:hover {
  border-color: var(--accent-9);
  background-color: var(--accent-6);
  color: var(--accent-0)
}

.Button_root__G_l9X:focus {
  --tw-shadow: 0 0 0 2px var(--accent-2);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px
}

.Button_root__G_l9X[data-active] {
  background-color: var(--accent-6)
}

.Button_loading__4K_aO {
  cursor: not-allowed;
  border-color: var(--accent-2);
  background-color: var(--accent-1);
  color: var(--accent-3)
}

.Button_slim__WmpZF {
  transform: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: none
}

.Button_ghost__zWAsk {
  border-width: 1px;
  border-color: var(--accent-2);
  background-color: var(--accent-0);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--accent-9)
}

.Button_ghost__zWAsk:hover {
  border-color: var(--accent-9);
  background-color: var(--accent-9);
  color: var(--accent-0)
}

.Button_naked__xwcQp {
  border-style: none;
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 600;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px
}

.Button_naked__xwcQp:hover, .Button_naked__xwcQp:focus {
  border-style: none;
  background-color: transparent
}

.Button_disabled__Ksgjy, .Button_disabled__Ksgjy:hover {
  cursor: not-allowed;
  border-color: var(--accent-2);
  background-color: var(--accent-1);
  color: var(--accent-4);
  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden
}

.Button_progress__X8l1J {
}

.LoadingDots_root__L3YhM {
  display: inline-flex;
  align-items: center;
  text-align: center;
  line-height: 1.75rem;
}

.LoadingDots_root__L3YhM .LoadingDots_dot__YIHAP {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  background-color: currentColor;
  -webkit-animation-name: LoadingDots_blink__8sST9;
          animation-name: LoadingDots_blink__8sST9;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  margin: 0 2px;
}

.LoadingDots_root__L3YhM .LoadingDots_dot__YIHAP:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.LoadingDots_root__L3YhM .LoadingDots_dot__YIHAP::nth-of-type(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes LoadingDots_blink__8sST9 {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes LoadingDots_blink__8sST9 {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.Categories_category__9M0kS {

  margin-bottom: 2rem;

  width: 100%;
}

.Categories_category__9M0kS:nth-of-type(odd) {

  margin-left: auto;
}

.Categories_categoryLink__POz7F {

  display: flex;

  align-items: center;

  gap: 0.75rem;
}

.Categories_categoryLink__POz7F:before {
  background-color: #000000;
  content: "";
  display: block;
  height: 2px;
  width: 25px;
}

.Categories_imgLink__B4Lf7 {

  position: relative;

  margin-bottom: 1rem;

  display: block;

  overflow: hidden;

  border-radius: 0.375rem;

  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  transition-property: box-shadow;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-duration: 150ms;
}

.Categories_imgLink__B4Lf7:hover {

  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Categories_imgLink__B4Lf7 {
  height: 560px;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (min-width: 640px) {
  .Categories_category__9M0kS {
    max-width: min(420px, 50% - 15px);
  }

  .Categories_category__9M0kS + .Categories_category__9M0kS {

    margin-top: -13rem;
  }
}
.Layout_root___g0be {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    background-color: var(--primary);
    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.Navbar_root__oKPSU {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  z-index: 40;
  background-color: var(--primary);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  min-height: 74px
}

.Navbar_nav__FsbqY {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  justify-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem
}

@media (min-width: 768px) {

  .Navbar_nav__FsbqY {
    padding-top: 1rem;
    padding-bottom: 1rem
  }
}

.Navbar_navMenu__lJ9fT {
  display: none
}

@media (min-width: 640px) {

  .Navbar_navMenu__lJ9fT {
    display: block
  }
}

.Navbar_openedMenu__Tg58X {
  display: block
}

.Navbar_logo__grQMD {
  justify-self: start
}

.Navbar_link__Z6GsF {
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.Navbar_link__Z6GsF:focus {
  color: var(--accent-8);
  outline: 2px solid transparent;
  outline-offset: 2px
}

.Navbar_actions__u_qeh {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-self: end
}

.Navbar_actionsBtn___sh9p {
  position: relative;
  padding: 0.5rem
}

.Navbar_cartItemsCount__SNLUf {
  position: absolute;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));

  border-radius: 50%;
  bottom: -0.3rem;
  line-height: 1;
  padding: 3px 6px;
  right: -0.3rem;
  text-align: center;
  vertical-align: middle
}

@media screen and (min-width: 640px) {
  .Navbar_nav__FsbqY {
    grid-template-columns: 1fr auto 1fr
  }

  .Navbar_link__Z6GsF {
    font-size: 1rem;
    line-height: 1.5rem
  }

  .Navbar_link__Z6GsF {
    color: var(--text-base)
  }

  .Navbar_link__Z6GsF {
    color: var(--accent-5)
  }

  .Navbar_link__Z6GsF:hover {
    color: var(--accent-9)
  }

  .Navbar_menuBtn__OctpL {
    display: none;
  }
}
.Cart_cartOffcanvas__e44T4 {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  padding: 1rem;
  background-color: #fff;
}

.Cart_cartOverlay___rizS {
  background-color: rgba(0, 0, 0, 0.5);
}

.Cart_lineItem__dknte + .Cart_lineItem__dknte {
  margin-top: 1rem;
}

@media (min-width: 768px) {

  .Cart_lineItem__dknte + .Cart_lineItem__dknte {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .Cart_cartOffcanvas__e44T4 {
    padding: 2rem;
  }
}
.LineItem_amountBtn__dO3TR,
.LineItem_amountInput__ciuLr {
  font-size: 1rem;
  font-weight: 600;
}

.LineItem_amountBtn__dO3TR {
  display: block;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));

  border: 1px solid #212121;
  border-radius: 4px;
  height: 30px;
  line-height: 1;
  width: 30px;
}

.LineItem_amountBtn__dO3TR:hover {
  background-color: #212121;
  border: 1px solid #212121;
  color: #ffffff;
}

.LineItem_amountInput__ciuLr {
  -moz-appearance: textfield;
  background-color: transparent;
  height: 30px;
  padding: 0 1.875rem;
  text-align: center;
  width: calc(2ch + 60px);
}

.LineItem_amountInput__ciuLr::-webkit-outer-spin-button,
.LineItem_amountInput__ciuLr::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Footer_root__r3DO1 {
  border-top-width: 1px;
  border-color: var(--accent-2);
}

.Footer_link__gh2xK > svg {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 75ms;
  transition-timing-function: linear;
}

.Footer_link__gh2xK:hover > svg {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.Footer_logo__XNu1u {
  margin-bottom: 1rem;
  display: block;
  height: 30px;
  width: 130px;
}

.Footer_logo__XNu1u svg {
  height: 100%;
  width: 100%;
}

.Footer_socialsDivider__vx525 {
  background-color: #212121;
  display: block;
  height: 1px;
  width: 10px;
}
