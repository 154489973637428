.category {
  @apply mb-8;

  width: 100%
}

.category:nth-of-type(odd) {
  @apply ml-auto;
}

.categoryLink {
  @apply flex items-center gap-3;
}

.categoryLink:before {
  background-color: #000000;
  content: "";
  display: block;
  height: 2px;
  width: 25px;
}

.imgLink {
  @apply block mb-4 rounded-md overflow-hidden shadow-sm hover:shadow-lg transition-shadow relative;
  height: 560px;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (min-width: 640px) {
  .category {
    max-width: min(420px, 50% - 15px);
  }

  .category + .category {
    @apply -mt-52;
  }
}